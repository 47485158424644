import React, { useState } from 'react';
import {
  cannonStyled,
  useCannonStyletron,
} from '../../../theme';
import PaypalSuccess from '../../../assets/payment-completed-card.svg';
import PayPalIcon from '../../../assets/paypal-icon.svg';
import { useOrientation, useTableOrMobile } from '../../../hooks';
import PayPal from '../../../atoms/PayPal';
import { Button, CustomDropdown } from '../../../atoms';
import TitleAndSubtitleAssitance from '../KeyAndCombinationForm/components/TitleAndSubtitleAssitance';

const ButtonContainer = cannonStyled('div', {
  paddingTop: '30px',
  width: '100%',
});

const MainContainer = cannonStyled(
  'div',
  ({ isRotated }: { isRotated: boolean }) => ({
    padding: isRotated ? '50px 0px 50px 0px' : '',
    width: '80%',
  })
);

interface ICombinationPaypal {
  title: string;
  subTitle: string;
}

const KeysPaypal = (props: ICombinationPaypal) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();

  //STATE
  const [amount, setAmount] = useState([
    { label: 'Please Choose an Option', id: '0' },
  ]);
  const [showPaypal, setShowPaypal] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [paypalCheckout, setPaypalCheckout] = useState(<></>);
  const currency = 'USD';

  // RENDER
  const { isRotated } = useOrientation();

  let isAmountSet = amount[0].label != 'Please Choose an Option';

  return (
    <MainContainer isRotated={isRotated}>
      <ButtonContainer>
        {showPaypal ? (
          <PayPal
            amount={parseInt(amount[0].id)}
            currency={'USD'}
            items={[]}
            successfulImage={PaypalSuccess}
            description={amount[0].label}
            successfulText={
              'We have sent your key(s) replacement confirmation to your mail. Be sure emails from cannonsecurityproducts.com and cannonsafe.com are on your safe sender list.'
            }
            successfulTitle={'Your payment was completed successfully'}
            onPaymentApproved={(value) => {
              // handleSendData(value);
              // setPaymentDone(true);
            }}
          />
        ) : (
          <>
            <PayPalIcon style={{ height: '120px' }} />
            <TitleAndSubtitleAssitance
              title={props.title}
              subTitle={props.subTitle}
            />
            <CustomDropdown
              clearable={true}
              noMarginLeft
              aria-labelledby='Keys required'
              aria-label='Keys required input'
              placeholder='Choose an option'
              background='white'
              label='Keys required'
              error={!isAmountSet && submited}
              errorMessage={'Please choose an option'}
              onChange={(value) => setAmount(value)}
              options={[
                { label: 'Key replacement (1 piece) - $20.00 USD', id: '20' },
                { label: 'Key replacement (2 pieces) - $40.00 USD', id: '40' },
                { label: 'Key replacement (3 pieces) - $60.00 USD', id: '60' },
                { label: 'Key replacement (4 pieces) - $80.00 USD', id: '80' },
              ]}
              value={amount}
              positive={amount[0].label !== ''}
              required
              requiredStyle
            />
            <Button.RoundedButton
              onClick={() => {
                setSubmited(true);
                if (isAmountSet) {
                  setShowPaypal(true);
                }
              }}
              size='compact'
              color='primary'
              $style={{
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '12px',
                paddingRight: '12px',
                width: '100%',
                marginTop: '30px',
              }}
            >
              Checkout
            </Button.RoundedButton>
          </>
        )}
      </ButtonContainer>
    </MainContainer>
  );
};

export default KeysPaypal;
